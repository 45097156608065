<template>
  <div class="layout-padding row justify-center background-whitesmoke items-center">
    <div class="col-xs-12 col-sm-5 col-lg-5">
      <q-card class="q-card-background-white">
        <q-card-main>
          <div class="row">
            <div class="col-sm-10 offset-sm-1 text-center">
              <img src="../assets/nroom.png" class="logo-sized">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8 offset-sm-2 text-left">
              <!-- <h5>ログイン</h5> -->
              <!-- <p style="font-size: 12px;">メールアドレスとパスワードを入力してください。</p> -->
            </div>
          </div>
          <div class="row center-row">
            <div class="col-sm-8 offset-sm-2">
              <q-field>
                <q-input id="mail" v-model="loginInfo.mail" label="メールアドレス" color="teal" type="email"/>
              </q-field>
            </div>
          </div>
          <div class="row center-row">
            <div class="col-sm-8 offset-sm-2">
              <q-field>
                <q-input id="password" v-model="loginInfo.password" label="パスワード" color="teal" type="password"/>
              </q-field>
            </div>
          </div>
          <div class="row center-row">
            <div class="col-sm-8 offset-sm-2">
              <p style="font-size: 12px; color: red;">{{ message }}</p>
            </div>
          </div>
          <div class="row center-right" style="padding-top: 20px;">
            <div class="col-sm-8 offset-sm-2 text-right">
              <q-btn class="logun-button" style="font-weight: bold;" @click="login()">ログイン</q-btn>
            </div>
          </div>
          <br>
        </q-card-main>
      </q-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import ApiService from '../typescript/service/ApiService';

interface ILoginInfo {
  mail: string;
  password: string;
}

export default defineComponent({
  data() {
    const loginInfo = reactive<ILoginInfo>({
      mail: '',
      password: '',
    });
    loginInfo.mail = 'admin';
    loginInfo.password = 'admin';
    return {
      loginInfo,
      display: true,
      leftDrawerOpen: true,
      rightDrawerOpen: false,
      message: '',
    };
  },
  methods: {
    login() {
      ApiService.getLogin(this.loginInfo)
        .then((res: any) => {
          if (res.status === 200 && res.data.length > 0) {
            (this as any).$emit('childEvent', true);
          } else {
            this.message = '※メールアドレスかパスワードに誤りがあります。';
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    },
  },
});
</script>

<style scoped lang="scss">
$base: #00e600;
$white: #fff;
.logun-button {
  background-color: $base;
  color: $white;
}
.logo-sized {
  width: 300px;
  margin: 20px;
}

@media screen and (max-width: 600px) {
  /* スマートフォン用スタイル */
  .logo-sized {
    width: 100%;
    max-width: 50%;
    margin-top: 10%;
  }
  .q-input {
    width: 100%;
    max-width: 80%;
  }
  .center-row {
    justify-content: center;
  }
  .center-right {
    justify-content: right;
    margin-right: 20px;
  }
}
</style>