<template>
  <div>
    <q-dialog v-model="dialog" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar :icon="icon" text-color="black" />
          <!-- <q-avatar :icon="icon" color="primary" text-color="white" /> -->
          <span class="q-ml-sm">検索条件を入力・選択してください。</span>
        </q-card-section>

        <div class="custom-width-container">
          <q-card-section>
            <div class="left">
              <span>検索条件</span>
              <q-select v-model="search.selectedKind" :options="kind" label="カテゴリー" emit-value map-options />
              <q-select v-model="search.selectedSite" :options="site" label="掲載サイト" emit-value map-options />
              <q-input Standard v-model="search.address" label="住所"></q-input>
              <q-input Standard v-model="search.name" label="マンション名"></q-input>
              <q-select v-model="search.selectedType1" :options="type1" label="契約種別" emit-value map-options />
              <!-- <div class="q-mt-md" style="display:none">
                選択されたアイテム: {{ search.selectedType1 }}
              </div> -->
              <div class="q-gutter-sm">
                <q-radio v-model="search.shape" val="all" label="全て"></q-radio>
                <q-radio v-model="search.shape" val="new" label="新着"></q-radio>
                <q-radio v-model="search.shape" val="insert_date" label="登録"></q-radio>
                <q-radio v-model="search.shape" val="update_date" label="更新"></q-radio>
                <q-radio v-model="search.shape" val="delete_date" label="削除"></q-radio>
              </div>
              <q-input Standard v-model="search.startDate" label="開始日付" mask="date" :rules="['date']" :readonly="dateFlag" :disabled="dateFlag">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer" v-show="!dateFlag">
                    <q-popup-proxy cover :breakpoint="600">
                      <q-date minimal v-model="search.startDate"></q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
              <q-input Standard v-model="search.endDate" label="終了日付" mask="date" :rules="['date']" :readonly="dateFlag" :disabled="dateFlag">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer" v-show="!dateFlag">
                    <q-popup-proxy cover :breakpoint="600">
                      <q-date minimal v-model="search.endDate"></q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="right" id="exclusion">
              <span>除外条件</span>
              <q-select v-model="search.selectedType2" :options="type2" label="契約種別" emit-value map-options />
            </div>
            <!-- <div class="q-mt-md" style="display:none">
              選択されたアイテム: {{ search.selectedType2 }}
            </div> -->
          </q-card-section>
        </div>

        <div>
          <q-card-actions align="right">
            <q-btn id="search-button" class="button base_color" v-close-popup>閉じる</q-btn>
            <q-btn id="search-button" class="button base_color" v-close-popup @click="conditions()">検索</q-btn>
          </q-card-actions>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>
  
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { outlinedManageSearch } from '@quasar/extras/material-icons-outlined'
import ApiService from '../typescript/service/ApiService';
import { ISearch } from '../typescript/interface/Property';
import { format } from 'date-fns';

export default defineComponent ({
  props: {
    'searchDialog': Boolean,
  },
  setup (props) {
    const search = ref<ISearch>({
      selectedSite: '',
      selectedKind: '',
      address: '',
      name: '',
      shape: '',
      selectedType1: '',
      selectedType2: '仲介',
      startDate: '',
      endDate: '',
    })
    const dialog = ref(props.searchDialog as boolean)
    const showDialog = async() => {
      dialog.value = !dialog.value
    }
    const currentDate = format(new Date(), 'yyyy/MM/dd');
    const dateFlag = ref(false);
    search.value.shape = 'all';
    search.value.startDate = currentDate;
    search.value.endDate = currentDate;
    const site = ref(['']);
    const kind = ref(['']);
    const type1 = ref(['']);
    const type2 = ref(['']);
    return {
      props,
      dialog,
      showDialog,
      search,
      type1,
      type2,
      site,
      kind,
      icon: outlinedManageSearch,
      dateFlag,
    }
  },
  data() {
    this.getSelectBoxData();
  },
  methods: {
    async getSelectBoxData() {
      await ApiService.getSelectBoxData()
      .then((res: any) => {
        res.data.site.forEach((v: any) => {this.site.push(v.site_name as never)});
        res.data.kind.forEach((v: any) => {this.kind.push(v.type as never)});
        res.data.type.forEach((v: any) => {
          this.type1.push(v.transaction_mode as never);
          this.type2.push(v.transaction_mode as never);
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
    },
    closeDaialog() {
      this.dialog = false;
    },
    pageTransition(pageName: string) {
      this.dialog = false;
      this.$router.push({ name: pageName });
    },
    conditions() {
      this.$emit('childEvent', this.search);
    },
    validateDates(dateFlag: boolean): void {
      this.dateFlag = dateFlag;
    },
  },
  watch: {
    searchDialog: function() {
      this.dialog = (this.searchDialog == false) ? true : this.searchDialog;
    },
    'search.shape': function(newVal, oldVal) {
      if (newVal == 'new' && oldVal != 'new') {
        this.validateDates(true);
      } else {
        this.validateDates(false);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
$white: #fff;
$base: #00e600;

.base_color {
  background-color: $base;
  color: $white;
  font-weight: bold;
}
#search-button {
  width: 60px;
  margin-bottom: 10px;
}
#exclusion {
  margin-top: 20px;
  padding-top: 20px;
}
</style>
