import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c9e8090"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginView = _resolveComponent("LoginView")!
  const _component_AllMenu = _resolveComponent("AllMenu")!
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.flag)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LoginView, {
            class: "home",
            onChildEvent: _ctx.childEvent
          }, null, 8, ["onChildEvent"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_AllMenu, { menuDialog: _ctx.menuDialog }, null, 8, ["menuDialog"])
    ]),
    (_ctx.flag)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_q_layout, { view: "hHh lpR fFf" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_header, {
                elevated: "",
                class: "bg-nroom text-white"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_toolbar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_toolbar_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Nroom")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_space),
                      _createVNode(_component_q_btn, {
                        flat: "",
                        round: "",
                        dense: "",
                        icon: "menu",
                        onClick: _ctx.openMenu
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_q_btn, {
                        flat: "",
                        round: "",
                        dense: "",
                        icon: "logout",
                        onClick: _ctx.logout
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}