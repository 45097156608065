import axios, { AxiosInstance } from "axios";

const apiClient: AxiosInstance = axios.create({
  // APIのURI
  baseURL: "https://web.nroom.jp:5000",
  // リクエストヘッダ
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

export default apiClient;
