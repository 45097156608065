import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f55ffe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { id: "tab" }
const _hoisted_3 = {
  style: {"display":"flex"},
  ref: "myElement"
}
const _hoisted_4 = { class: "tabMenu" }
const _hoisted_5 = { id: "tabButton" }
const _hoisted_6 = { class: "parent" }
const _hoisted_7 = { class: "tabContents" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = { key: 4 }
const _hoisted_13 = { key: 5 }
const _hoisted_14 = { key: 6 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_vue_element_loading = _resolveComponent("vue-element-loading")!
  const _component_PropertyInfo1 = _resolveComponent("PropertyInfo1")!
  const _component_PropertyInfo2 = _resolveComponent("PropertyInfo2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(" うちーならいふ "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isSelect('1')))
          }, "物件情報①"),
          _createElementVNode("li", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isSelect('2')))
          }, "物件情報②"),
          _createElementVNode("li", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isSelect('3')))
          }, "キャッチコピー"),
          _createElementVNode("li", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSelect('4')))
          }, "設備・特徴"),
          _createElementVNode("li", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isSelect('5')))
          }, "画像"),
          _createElementVNode("li", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isSelect('6')))
          }, "動画"),
          _createElementVNode("li", {
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isSelect('7')))
          }, "公開情報")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_q_btn, {
            id: "regist-button",
            class: "button base-color",
            style: {"font-weight":"bold"},
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.regist()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("登録")
            ]),
            _: 1
          })
        ])
      ], 512),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_vue_element_loading, {
          active: _ctx.show,
          size: "128",
          spinner: "spinner"
        }, null, 8, ["active"]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.isActive === '1')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_PropertyInfo1)
              ]))
            : (_ctx.isActive === '2')
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_PropertyInfo2)
                ]))
              : (_ctx.isActive === '3')
                ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                : (_ctx.isActive === '4')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                  : (_ctx.isActive === '5')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12))
                    : (_ctx.isActive === '6')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13))
                      : (_ctx.isActive === '7')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14))
                        : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}