<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js + TypeScript App"/> -->
    <AllMenu :menuDialog="menuDialog" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AllMenu from '../components/AllMenu.vue';

export default defineComponent({
  components: {
    AllMenu,
  },
  data() {
    return {
      menuDialog: true,
    }
  },
});
</script>
