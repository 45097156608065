<template>
  <q-container>
    <!-- <q-card> -->
      <q-card-section class="card">
        <div style="margin-top:30px;">
          <span class="q-ml-sm card-title">共通</span>
        </div>
        <q-row class="row">
          <q-select class="input" outlined v-model="propertyInfo1.category" :options="options" label="カテゴリー"></q-select>
        </q-row>
        <q-row class="row">
          <q-input class="input" outlined v-model="propertyInfo1.zipCode" label="郵便番号" />
          <q-input class="input" outlined v-model="propertyInfo1.municipality" label="市町村" />
          <q-input class="input" outlined v-model="propertyInfo1.district" label="地区" />
        </q-row>
        <q-row class="row">
          <q-input class="input" outlined v-model="propertyInfo1.chome" label="丁目" />
          <q-input class="input" outlined v-model="propertyInfo1.streetAddress" label="番地" />
          <q-input class="input" outlined v-model="propertyInfo1.issue" label="号" />
        </q-row>
        <div v-if="isActive === true">
          <div style="margin-top:30px;">
            <span class="q-ml-sm card-title">戸建て専用</span>
          </div>
          <q-row class="row">
            <q-input class="input" outlined v-model="propertyInfo1.house1.propertyName" label="建物名" />
          </q-row>
          <q-row class="row">
            <q-select class="input" outlined v-model="propertyInfo1.house1.structure" :options="options" label="構造"></q-select>
            <q-input class="input" outlined v-model="propertyInfo1.house1.numberOfFloors" label="階数" />
          </q-row>
          <q-row class="row">
            <q-select class="input" outlined v-model="propertyInfo1.house1.constructionYear" :options="options" label="建築年"></q-select>
            <q-select class="input" outlined v-model="propertyInfo1.house1.constructionMonth" :options="options" label="建築月"></q-select>
          </q-row>
          <q-row class="row">
            <q-input class="input" outlined v-model="propertyInfo1.house1.buildingArea" label="建物面積（㎡）" />
            <q-select class="input" outlined v-model="propertyInfo1.house1.currentSituation" :options="options" label="現況"></q-select>
          </q-row>
          <q-row class="row">
            <q-input class="input" outlined v-model="propertyInfo1.house1.monorailStation" label="モノレール駅" />
            <q-input class="input" outlined v-model="propertyInfo1.house1.foot" label="徒歩（分）" />
            <q-input class="input" outlined v-model="propertyInfo1.house1.bus" label="バス（分）" />
          </q-row>
          <q-row class="row">
            <q-input class="input" outlined v-model="propertyInfo1.house1.busStopName" label="バス停名" />
            <q-input class="input" outlined v-model="propertyInfo1.house1.footMinute" label="徒歩（分）" />
          </q-row>
        </div>
      </q-card-section>
    <!-- </q-card> -->
  </q-container>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from 'vue';
import { PropertyInfo1 } from '../../typescript/interface/Property';
import { House1 } from '../../typescript/interface/Property';

export default defineComponent({
  setup() {
    const isActive = ref(false);
    const house: Ref<House1> = ref({
        propertyName: '',
        structure: '',
        numberOfFloors: '',
        constructionYear: '',
        constructionMonth: '',
        buildingArea: '',
        newHouseFlag: false,
        currentSituation: '',
        monorailStation: '',
        foot: '',
        bus: '',
        busStopName: '',
        footMinute: '',
    });
    const propertyInfo1: Ref<PropertyInfo1> = ref({
        category: '',
        name: '',
        zipCode: '',
        municipality: '',
        district: '',
        chome: '',
        streetAddress: '',
        issue: '',
        house1: house,
    });

    return {
      isActive,
      propertyInfo1,
      options: [
        'マンション', '戸建て'
      ]
    };
  },
  data() {
    return {
    };
  },
  methods: {
  },
  watch: {
    propertyInfo1: {
      deep: true,
      handler() {
        this.isActive = (this.propertyInfo1.category == '戸建て') ? true : false;
      }
    }
  }
});
</script>

<style scoped lang="scss">
.home {
  width: 100vw;
}
.input {
  width: 30%;
  margin-right: 10px;
}
.row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.card {
  padding-bottom: 40px;
}
.card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
  