import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e47063b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "map" }
const _hoisted_2 = { id: "content" }
const _hoisted_3 = { id: "content" }
const _hoisted_4 = { id: "content" }
const _hoisted_5 = { id: "content" }
const _hoisted_6 = { id: "content" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoWindow = _resolveComponent("InfoWindow")!
  const _component_Marker = _resolveComponent("Marker")!
  const _component_GoogleMap = _resolveComponent("GoogleMap", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.coordinates)
      ? (_openBlock(), _createBlock(_component_GoogleMap, {
          key: 0,
          apiKey: _ctx.apiKey,
          id: "googlemap",
          center: _ctx.coordinates,
          zoom: 15,
          fullscreenControl: false
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customMarkerProps, (coord, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createVNode(_component_Marker, {
                  options: { position: coord.position, title: `Marker ${index + 1}` },
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mapClick()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InfoWindow, {
                      modelValue: _ctx.infowindow[index],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.infowindow[index]) = $event)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(coord.id), 1),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(coord.type), 1),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(coord.name), 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(coord.price), 1),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("a", {
                            onClick: ($event: any) => (_ctx.generateAndOpenPdf(coord.id)),
                            href: "#"
                          }, "物件概要書", 8, _hoisted_7)
                        ]),
                        (coord.photo)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: coord.photo,
                              style: {"width":"150px","height":"150px"}
                            }, null, 8, _hoisted_8))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1032, ["options"])
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["apiKey", "center"]))
      : _createCommentVNode("", true)
  ]))
}