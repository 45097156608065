<template>
  <div id="map">
    <!-- <q-btn id="search-button" color="primary" style="font-weight: bold;" @click="getGoogleMap()">検索</q-btn> -->
    <!-- <q-btn id="mansion-button" color="primary" style="font-weight: bold;" @click="filterMansion()">マンション</q-btn>
    <q-btn id="house-button" color="primary" style="font-weight: bold;" @click="filterHouse()">戸建て</q-btn>
    <q-btn id="tochi-button" color="primary" style="font-weight: bold;" @click="filterTochi()">土地</q-btn>
    <q-btn id="all-button" color="primary" style="font-weight: bold;" @click="filterAll()">全て</q-btn> -->
    
    <GoogleMap v-if="coordinates" :apiKey="apiKey" id="googlemap" :center="coordinates" :zoom="15" :fullscreenControl="false">
      <div v-for="(coord, index) in customMarkerProps" :key="index">
        <Marker :options="{ position: coord.position, title: `Marker ${index + 1}` }" @click="mapClick()">
          <InfoWindow v-model="infowindow[index]">
            <div id="content">{{ coord.id }}</div>
            <div id="content">{{ coord.type }}</div>
            <div id="content">{{ coord.name }}</div>
            <div id="content">{{ coord.price }}</div>
            <div id="content"><a @click="generateAndOpenPdf(coord.id)" href="#">物件概要書</a></div>
            <img v-if="coord.photo" :src="coord.photo" style="width: 150px; height: 150px;" />
          </InfoWindow>
        </Marker>
      </div>
    </GoogleMap>
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect, Ref, ref } from "vue";
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";
import ApiService from '../typescript/service/ApiService';
import { CustomMarkerProps, Coordinates } from '../typescript/interface/Property';

export default defineComponent({
  components: { GoogleMap, Marker, InfoWindow },
  props: {
    data: {
      type: Array as () => CustomMarkerProps[],
      required: true,
    },
  },
  setup(props) {
    const apiKey = "AIzaSyDTDDTf6RAcNPalIksGvbpi-rSD8Mixtxo";
    const locationName = ref("");
    const coordinates: Ref<Coordinates | null> = ref(null);
    const customMarkerProps: Ref<CustomMarkerProps[]> = ref([]);
    const customMarkerPropsByAll: Ref<CustomMarkerProps[]> = ref([]);
    const customMarkerPropsByMansion: Ref<CustomMarkerProps[]> = ref([]);
    const customMarkerPropsByHouse: Ref<CustomMarkerProps[]> = ref([]);
    const customMarkerPropsByTochi: Ref<CustomMarkerProps[]> = ref([]);
    const infowindow: Ref<boolean[]> = ref([]);
    const count: Ref<number> = ref(0);

    locationName.value = "沖縄市";

    watchEffect(() => {
      customMarkerProps.value = props.data;
      customMarkerPropsByAll.value = props.data;
    });

    return { 
      apiKey,
      locationName,
      coordinates,
      customMarkerProps,
      customMarkerPropsByAll,
      customMarkerPropsByMansion,
      customMarkerPropsByHouse,
      customMarkerPropsByTochi,
      infowindow,
      count,
    };
  },
  data() {
    // 画面起動時にGoogleMapを表示
    this.getGoogleMap();
    return {};
  },
  methods: {
    mapClick() {
      // 他のマーカーをクリックしたときに、他のInfoWindowを閉じる処理
      this.infowindow.map((row: boolean, index: number) => {
        this.infowindow[index] = false;
      });
    },
    async getGoogleMap() {
      try {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.locationName}&key=${this.apiKey}`);
        const data = await response.json();
        
        if (data.results && data.results.length > 0) {
          const result = data.results[0].geometry.location;
          this.coordinates = { lat: result.lat, lng: result.lng };
          // this.getPropertyInfo();

        } else {
          console.error("Geocoding failed");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    // filterMansion() {
    //   this.customMarkerProps = this.customMarkerPropsByAll.filter((row: CustomMarkerProps) => {
    //     return row.kind === "mansion";
    //   });
    // },
    // filterHouse() {
    //   this.customMarkerProps = this.customMarkerPropsByAll.filter((row: CustomMarkerProps) => {
    //     return row.kind === "house";
    //   });
    // },
    // filterTochi() {
    //   this.customMarkerProps = this.customMarkerPropsByAll.filter((row: CustomMarkerProps) => {
    //     return row.kind === "tochi";
    //   });
    // },
    // filterAll() {
    //   this.customMarkerProps = this.customMarkerPropsByAll;
    // },
    async generateAndOpenPdf(id: string) {
      const pdfBlob = await ApiService.getPdfData(id);
      const uint8ArrayData = new Uint8Array(pdfBlob.data[0].pdf_file.data);
      const blob = new Blob([uint8ArrayData], { type: 'application/pdf' });
      // BlobデータをURLに変換
      const pdfUrl = URL.createObjectURL(blob);
      // 新しいタブでPDFを開く
      window.open(pdfUrl, '_blank');
    },
  },
});
</script>

<style scoped lang="scss">
#map {
  width: 100%;
  height: 500px;
}
#googlemap {
  width: 100%;
  height: 500px;
}
#search-text {
  position: absolute;
  top: 120px;
  left: (100% - 25%);
  z-index: 1;
}
// #search-button {
//   position: absolute;
//   top: 270px;
//   width: 120px;
//   margin-left: 10px;
//   z-index: 1;
// }
#mansion-button {
  position: absolute;
  top: 70px;
  width: 120px;
  margin-left: 10px;
  z-index: 1;
}
#house-button {
  position: absolute;
  top: 120px;
  width: 120px;
  margin-left: 10px;
  z-index: 1;
}
#tochi-button {
  position: absolute;
  top: 170px;
  width: 120px;
  margin-left: 10px;
  z-index: 1;
}
#all-button {
  position: absolute;
  top: 220px;
  width: 120px;
  margin-left: 10px;
  z-index: 1;
}
</style>
