<template>
  <q-container>
    <!-- <q-card> -->
      <q-card-section class="card">
        <div style="margin-top:30px;">
          <span class="q-ml-sm card-title">共通</span>
        </div>
        <q-row class="row">
          <q-select class="input" outlined v-model="propertyInfo2.transactionMode" :options="transactionMode" label="取引業態"></q-select>
        </q-row>
        <q-row class="row">
          <q-input class="input" outlined v-model="propertyInfo2.price" label="価格" suffix="万円" />
        </q-row>
        <q-row class="row">
          <q-input type="textarea" class="input text-area" outlined v-model="propertyInfo2.priceMemo" label="価格備考(200文字以内)" />
        </q-row>
        <q-row class="row">
          <q-input type="number" class="input" outlined v-model="propertyInfo2.floorPlanCount" label="間取り(部屋数:数値のみ入力可)" />
          <q-select class="input" outlined v-model="propertyInfo2.floorPlanType" :options="floorPlanType" label="間取り種別"></q-select>
        </q-row>
        <q-row class="row" v-for="count in floorCount" :key="count">
          <q-select class="input-middle" outlined v-model="propertyInfo2.floorKind1" :options="floorType[Number(count-1)]" label="種別"></q-select>
          <q-input type="number" class="input-middle" outlined v-model="propertyInfo2.floorSize1" label="間取り" suffix="帖" />
        </q-row>

        <div v-if="isHouse === true">
          <div style="margin-top:30px;">
            <span class="q-ml-sm card-title">戸建て専用</span>
          </div>

        </div>
      </q-card-section>
    <!-- </q-card> -->
  </q-container>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from 'vue';
import { PropertyInfo2 } from '../../typescript/interface/Property';
export default defineComponent({
  setup() {
    const isManstion = ref(false);
    const isHouse = ref(false);
    const floorCount = ref(0);
    const floor = ['洋', '和'];
    let floorType = [floor, floor, floor, floor, floor];
    console.log(floorType);
    const propertyInfo2: Ref<PropertyInfo2> = ref({
      transactionMode: '',
      price: '',
      priceMemo: '',
      floorPlanCount: '',
      floorPlanType: '',
      floorKind1: '洋',
      floorKind2: '洋',
      floorKind3: '洋',
      floorKind4: '洋',
      floorKind5: '洋',
      floorSize1: '',
      floorSize2: '',
      floorSize3: '',
      floorSize4: '',
      floorSize5: '',
      floorMainSize: '',
      floorMemo: '',
    });

    return {
      isManstion,
      isHouse,
      floorType,
      floorCount,
      propertyInfo2,
      transactionMode: [
        '選択してください', '売主', '代理', '専属専任媒介', '専任媒介', '一般媒介', '仲介'
      ],
      floorPlanType: [
        '選択してください',
        'ルーム',
        'K(キッチン)',
        'DK(ダイニングキッチン)',
        'SDK(サービスルーム・ダイニングキッチン)',
        'LDK(リビングダイニングキッチン)',
        'SLDK(サービスルーム・リビングダイニングキッチン)'
      ],
    };
  },
  data() {
    return {
    user: {
      name: '',
      email: '',
      password: ''
    }
    };
  },
  methods: {
  },
  watch: {
    propertyInfo2: {
      deep: true,
      handler() {
        if (Number(this.propertyInfo2.floorPlanCount) >= 6) {
          // 上限値超え
        }
        if (this.propertyInfo2.floorPlanCount.length > 0 && this.propertyInfo2.floorPlanType != '' && this.propertyInfo2.floorPlanType != this.floorPlanType[0]) {
          if (Number(this.propertyInfo2.floorPlanCount) <= 5 && Number(this.propertyInfo2.floorPlanCount) >= 0) {
            this.floorCount = Number(this.propertyInfo2.floorPlanCount);
          }
        }
        if (this.propertyInfo2.floorPlanCount.length == 0 || this.propertyInfo2.floorPlanType == '' || this.propertyInfo2.floorPlanType == this.floorPlanType[0]) {
          this.floorCount = 0;
        }
      }
    }
  }
});
</script>

<style scoped lang="scss">
.home {
  width: 100vw;
}
.input {
  width: 30%;
  margin-right: 10px;
}
.input-middle {
  width: 10%;
}
.text-area {
  width: 80%;
}
.row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.card {
  padding-bottom: 40px;
}
.card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
