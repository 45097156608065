<template>
  <div id="app" class="table-container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col"/>
          <th scope="col">掲載サイト</th>
          <th scope="col">名前・住所</th>
          <th scope="col">カテゴリー</th>
          <th scope="col">カテゴリー詳細</th>
          <th scope="col">契約種別</th>
          <th scope="col">築年数</th>
          <th scope="col">価格</th>
          <th scope="col">成約金額</th>
          <th scope="col">建物（専有）面積</th>
          <th scope="col">土地面積</th>
          <th scope="col">取扱い不動産会社</th>
          <th scope="col">電話番号</th>
          <th scope="col">登録日付</th>
          <th scope="col">更新日付</th>
          <th scope="col">成約日付</th>
          <th scope="col">掲載終了日付</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in property" :key="index">
          <th scope="row">{{ index+1 }}</th>
          <td>{{ item.siteName }}</td>
          <td><a target="_blank" :href="item.url">{{ item.name }}</a></td>
          <td>{{ item.type }}</td>
          <td>
            <q-select v-model="item.kind2" :options="kinds" label="カテゴリー" emit-value map-options />
          </td>
          <td>{{ item.transactionMode }}</td>
          <td>{{ item.age }}</td>
          <td>{{ item.price }}</td>
          <td>
            <q-input Standard v-model="item.contractAmount" label="成約金額"></q-input>
          </td>
          <td>{{ item.exclusiveArea }}</td>
          <td>{{ item.parking }}</td>
          <td>{{ item.companyName }}</td>
          <td>{{ item.companyTel }}</td>
          <td>{{ item.insertDate }}</td>
          <td>{{ item.updateDate }}</td>
          <td class="table-date">
            <q-input Standard v-model="item.contractDate" label="成約日付" mask="date" :rules="['date']">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy cover :breakpoint="600">
                      <q-date minimal v-model="item.contractDate"></q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
          </td>
          <td>{{ item.deleteDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect, Ref, ref } from 'vue';
import { CustomMarkerProps } from '../typescript/interface/Property';

interface IProperty {
  siteName?: string;
  type?: string;
  kind2?: string;
  title?: string;
  name?: string;
  transactionMode?: string;
  age?: number;
  price?: string;
  contractAmount?: string;
  exclusiveArea?: string;
  parking?: string;
  companyName?: string;
  companyTel?: string;
  url?: string;
  insertDate?: string;
  updateDate?: string;
  contractDate?: string;
  deleteDate?: string;
}

export default defineComponent({
  props: {
    data: {
      type: Array as () => CustomMarkerProps[],
      required: true,
    },
  },
  setup(props) {
    const property: Ref<IProperty[]> = ref([]);
    const kinds = ref(['']);

    kinds.value.push('新築戸建て');
    kinds.value.push('中古戸建て');
    kinds.value.push('新築マンション');
    kinds.value.push('中古マンション');
    kinds.value.push('土地');
    kinds.value.push('軍用地');
    kinds.value.push('外人住宅');
    kinds.value.push('アパート');
    kinds.value.push('商業ビル');
    kinds.value.push('住宅兼店舗');
    kinds.value.push('店舗のみ');
    
    const getDisplayList = (row: any) => {
      property.value.push({
        siteName: row.siteName,
        type: row.type,
        kind2: row.kind2,
        title: row.title,
        name: row.name,
        transactionMode: row.transactionMode,
        age: row.age,
        price: row.price,
        exclusiveArea: row.exclusiveArea,
        parking: row.parking,
        companyName: row.companyName,
        companyTel: row.companyTel,
        url: row.url,
        insertDate: row.insertDate,
        updateDate: row.updateDate,
        deleteDate: row.deleteDate,
      });
    };

    watchEffect(() => {
      property.value = [];
      props.data.forEach((row: any) => {
        getDisplayList(row);
      });
    });

    return {
      property,
      kinds,
    }
  },
  data() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.table-container {
  /* スクロールバーを表示する高さを設定 */
  // max-height: calc(100vh);
  // max-height: calc(100vh);
  max-width: calc(100vw - 20px);
  /* 縦方向にスクロール可能にする */
  // overflow-y: auto;
  /* 横方向にスクロール可能にする */
  overflow-x: auto;
  /* 任意でボーダーを追加 */
  // border: 1px solid #ddd;
}
.table {
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
}

th, td {
  white-space: nowrap;
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}
</style>
