import http from "../../../http-common";
import { ISearch } from "../interface/Property";

class ApiService {
  // getTest(data: any): Promise<any> {
  //   return http.get(`/api/test?mail=${data.mail}&password=${data.password}`);
  // }

  /******** ログイン ********/
  getLogin(data: any) {
    return http.get(`/api/login?loginId=${data.mail}&password=${data.password}`);
  }

  /******** 物件情報 ********/
  getPropertyInfo(conditions: ISearch) {
    return http.get(`/api/propertyInfo`, { params: conditions });
  }

  getPdfData(id: string) {
    return http.get(`/api/pdf?id=${id}`);
  }

  getSelectBoxData() {
    return http.get(`/api/selectBox`);
  }


}

export default new ApiService();
