<template>
  <div class="home">
    <div>
      <SearchMenu :searchDialog="searchDialog" @childEvent="handleChildEvent"/>
    </div>
    <div id="tab">
      <div style="display: flex;" ref="myElement">
        <ul class="tabMenu">
          <li @click="isSelect('1')">在庫一覧</li>
          <li @click="isSelect('2')">グーグルマップ</li>
        </ul>
        <div id="tabButton">
          <q-btn id="today-new-button" class="button sp color-blue" @click="newPropertyInfo()">本日の新着</q-btn>
          <!-- <q-btn id="today-delete-button" class="button sp color-blue" @click="deletePropertyInfo()">本日の削除</q-btn> -->

          <q-btn id="update-button" class="button sp color-base" @click="updatePropertyInfo()">更新</q-btn>
          <!-- <q-btn id="delete-button" class="button sp color-base" @click="deletePropertyInfo()">削除</q-btn> -->
          <q-btn id="search-button" class="button color-base" @click="openSearch()">検索</q-btn>
        </div>
      </div>
      <div class="parent">
        <vue-element-loading :active="show" size="128" spinner="spinner" />
          <div class="tabContents">
          <div v-if="isActive === '1'">
            <PropertyList :data="data"/>
          </div>
          <div v-else-if="isActive === '2'">
            <GoogleMap :data="data"/>
          </div>
        </div>
      </div>
      <!-- <button @click="test()">要素の縦幅と横幅を取得</button>
      <p>縦幅: {{ height }}px</p>
      <p>横幅: {{ width }}px</p> -->

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';
import GoogleMap from '../components/GoogleMap.vue';
import PropertyList from '../components/PropertyList.vue';
import ApiService from '../typescript/service/ApiService';
import { CustomMarkerProps } from '../typescript/interface/Property';
import VueElementLoading from "vue-element-loading";
import SearchMenu from "../components/SearchMenu.vue";
import { ISearch } from '../typescript/interface/Property';
import { format } from 'date-fns';

export default defineComponent({
  name: 'PropertyInfoView',
  components: {
    GoogleMap,
    PropertyList,
    VueElementLoading,
    SearchMenu,
  },
  setup() {
    const locationName = ref("");
    const infowindow: Ref<boolean[]> = ref([]);
    const data: Ref<CustomMarkerProps[]> = ref([]);
    const show: Ref<boolean> = ref(false);
    const conditions: Ref<ISearch> = ref({
      address: '',
      name: '',
      type1: [],
      type2: [],
      shape: '',
    });
    return {
      locationName,
      infowindow,
      data,
      show,
      conditions,
    }
  },
  data() {
    return {
      isActive: '1',
      searchDialog: false,
      height: 0,
      width: 0,
    }
  },
  methods: {
    test() {
      this.height = (this.$refs.myElement as HTMLElement).offsetHeight;
      this.width = (this.$refs.myElement as HTMLElement).offsetWidth;
      console.log(this.height);
      console.log(this.width);
    },
    isSelect: function (num: string) {
      this.isLoading(true);
      this.isActive = num;
      setTimeout(() => {
        this.isLoading(false);
      }, 2000);
    },
    isLoading(flag: boolean) {
      this.show = flag;
    },
    updatePropertyInfo() {
      console.log('updatePropertyInfo');
    },
    newPropertyInfo() {
      console.log('newPropertyInfo');
      this.conditions.selectedSite = '';
      this.conditions.selectedKind = '';
      this.conditions.address = '';
      this.conditions.name = '';
      this.conditions.selectedType1 = '';
      this.conditions.selectedType2 = '仲介';
      this.conditions.shape = 'new';
      this.conditions.startDate = '';
      this.conditions.endDate = '';
      this.getPropertyInfo(this.conditions);
    },
    deletePropertyInfo() {
      console.log('deletePropertyInfo');
      this.conditions.selectedSite = '';
      this.conditions.selectedKind = '';
      this.conditions.address = '';
      this.conditions.name = '';
      this.conditions.selectedType1 = '';
      this.conditions.selectedType2 = '仲介';
      this.conditions.shape = 'delete_date';
      this.conditions.startDate = format(new Date(), 'yyyy-MM-dd');
      this.conditions.endDate = '';
      this.getPropertyInfo(this.conditions);
    },
    openSearch() {
      this.searchDialog = !this.searchDialog;
    },
    handleChildEvent(dataFromChild: ISearch) {
      this.conditions = dataFromChild;
      this.getPropertyInfo(this.conditions);
    },
    async getPropertyInfo(conditions: ISearch) {
      this.data = [];
      this.isLoading(true);
      await ApiService.getPropertyInfo(conditions)
        .then((res: any) => {
          res.data.forEach((row: any) => {
            this.createCustomMarkerProps(row);
          });
        })
        .catch((err: any) => {
          console.log(err);
        });
      this.isLoading(false);
    },
    createCustomMarkerProps(row: any) {
      let parking = '';
      if (row.kind == 'house') {
        parking = row.parking;
      } else if (row.kind == 'tochi') {
        parking = row.exclusive_area;
      }
      
      this.data.push(
        {
          id: row.id,
          siteName: row.site_name,
          title: row.title,
          kind: row.kind,
          type: (row.type as string).replace('売買', ''),
          name: (row.name == '') ? row.address : row.name.substring(0, 15) + '...',
          price: row.price,
          transactionMode: row.transaction_mode,
          age: (row.kind == 'tochi') ? '' : row.age,
          exclusiveArea: (row.kind == 'tochi') ? '' : row.exclusive_area,
          parking: parking,
          companyName: row.company_name,
          companyTel: row.company_tel,
          url: row.url,
          photo: row.photo,
          insertDate: row.insert_date,
          updateDate: row.update_date,
          deleteDate: row.delete_date,
          position: { 
            lat: row.lat,
            lng: row.lng
          },
        }
      );
      this.infowindow.push(false);
    },
  },
});
</script>

<style scoped lang="scss">
$green: #007db9;
$white: #fff;
$base: #00e600;
.color-base {
  background-color: $base;
  color: $white;
}
.color-blue {
  background-color: $green;
  color: $white;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#tab {
  .tabMenu {
    margin-top: 10px;
    display: flex;
    li {
      text-align: center;
      width: 140px;
      padding: 10px 20px;
      color: $white;
      border-right: 1px solid $white;
      background-color: $base;
      cursor: pointer;
            
      &:last-child {
        border-right: none;
      }
    }
  }
  #tabButton {
    margin-top: 10px;
    margin-left: auto;
    margin-right: 20px;
    .button {
      font-weight: bold;
      margin-left: 10px;
    }
  }
}

/* スマホ対応 */
@media (max-width: 600px) {
  .sp {
    display: none;
  }
  .home {
    width: 100%;
    height: 100%;
  }
}

</style>
