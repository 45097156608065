<template>
  <div>
    <div v-if="!flag">
      <LoginView class="home" @childEvent="childEvent"></LoginView>
    </div>
    <div>
      <AllMenu :menuDialog="menuDialog" />
    </div>

    <div v-if="flag">
      <q-layout view="hHh lpR fFf">
        <q-header elevated class="bg-nroom text-white">
          <q-toolbar>
            <q-toolbar-title>Nroom</q-toolbar-title>
            <q-space />
            <q-btn flat round dense icon="menu" @click="openMenu" />
            <q-btn flat round dense icon="logout" @click="logout" />
          </q-toolbar>
        </q-header>

        <router-view />

        <!-- <q-footer class="bg-nroom">
          <q-toolbar>
            <q-toolbar-title>
              Your Footer Title
            </q-toolbar-title>
          </q-toolbar>
        </q-footer> -->
      </q-layout>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import LoginView from './views/LoginView.vue'
import AllMenu from './components/AllMenu.vue';
import PropertyInfoView from './views/PropertyInfoView.vue'

export default defineComponent({
  components: {
    LoginView,
    AllMenu,
  },
  setup() {
    const flag = ref(false);
    const childEvent = (childFlag: boolean) => {
      flag.value = childFlag;
    };
    return {
      flag,
      childEvent,
    }
  },
  data() {
    return {
      menuDialog: false,
    }
  },
  methods: {
    openMenu() {
      this.menuDialog = !this.menuDialog;
    },
    logout() {
      this.flag = !this.flag;
      this.$router.push("/");
    },
  }
});
</script>

<style scoped lang="scss">
$white: #fff;
$base: #00e600;
.bg-nroom {
  background-color: $base;
}
.home {
  margin-top: 60px;
}
</style>
