<template>
  <div class="home">
    うちーならいふ
    <div id="tab">
      <div style="display: flex;" ref="myElement">
        <ul class="tabMenu">
          <li @click="isSelect('1')">物件情報①</li>
          <li @click="isSelect('2')">物件情報②</li>
          <li @click="isSelect('3')">キャッチコピー</li>
          <li @click="isSelect('4')">設備・特徴</li>
          <li @click="isSelect('5')">画像</li>
          <li @click="isSelect('6')">動画</li>
          <li @click="isSelect('7')">公開情報</li>
        </ul>
        <div id="tabButton">
          <q-btn id="regist-button" class="button base-color" style="font-weight: bold;" @click="regist()">登録</q-btn>
        </div>
      </div>
      <div class="parent">
        <vue-element-loading :active="show" size="128" spinner="spinner" />
          <div class="tabContents">
          <div v-if="isActive === '1'">
            <PropertyInfo1/>
          </div>
          <div v-else-if="isActive === '2'">
            <PropertyInfo2/>
          </div>
          <div v-else-if="isActive === '3'">
          </div>
          <div v-else-if="isActive === '4'">
          </div>
          <div v-else-if="isActive === '5'">
          </div>
          <div v-else-if="isActive === '6'">
          </div>
          <div v-else-if="isActive === '7'">
          </div>
        </div>
      </div>
      <!-- <button @click="test()">要素の縦幅と横幅を取得</button>
      <p>縦幅: {{ height }}px</p>
      <p>横幅: {{ width }}px</p> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from 'vue';
import PropertyInfo1 from '../components/regist/PropertyInfo1.vue';
import PropertyInfo2 from '../components/regist/PropertyInfo2.vue';

export default defineComponent({
  name: 'UtinaLifeView',
  components: {
    PropertyInfo1,
    PropertyInfo2,
  },
  setup() {
    const show: Ref<boolean> = ref(false);
    return {
      show,
    };
  },
  data() {
    return {
      isActive: '1',
    };
  },
  methods: {
    isLoading(flag: boolean) {
      this.show = flag;
    },
    isSelect: function (num: string) {
      this.isActive = num;
    },
    regist() {
      console.log('登録ボタンが押されました');
    },
  }
});
</script>

<style scoped lang="scss">
$white: #fff;
$base: #00e600;
.home {
  width: 100%;
}
.base-color {
  background-color: $base;
  color: $white;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#tab {
  .tabMenu {
    margin-top: 10px;
    display: flex;
    li {
      text-align: center;
      width: 140px;
      padding: 10px 20px;
      color: $white;
      border-right: 1px solid $white;
      background-color: $base;
      cursor: pointer;
            
      &:last-child {
        border-right: none;
      }
    }
  }
  #tabButton {
    margin-top: 10px;
    margin-left: auto;
    margin-right: 20px;
    .button {
      margin-left: 10px;
    }
  }
}
</style>
