<template>
  <div class="menu">
    <q-dialog v-model="dialog" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <!-- <q-avatar icon="signal_wifi_off" color="primary" text-color="white" /> -->
          <img src="../assets/logo_n.png" class="logo-sized">
          <span class="q-ml-sm">メニューからお選びください。</span>
        </q-card-section>

        <q-card-section>
          <span class="q-ml-sm card-title">市場</span>
          <q-btn id="menu-button" color="white" text-color="black" label="物件確認" @click="pageTransition('propertyInfo')"/>
          <q-btn id="menu-button" color="white" text-color="black" label="当日物件" @click="pageTransition('')"/>
          <q-btn id="menu-button" color="white" text-color="black" label="未作成" @click="pageTransition('')"/>
        </q-card-section>

        <q-card-section>
          <span class="q-ml-sm card-title">ネット広告</span>
          <q-btn id="menu-button" color="white" text-color="black" label="うちなーらいふ" @click="pageTransition('utinaLife')"/>
          <q-btn id="menu-button" color="white" text-color="black" label="未作成" @click="pageTransition('')"/>
          <q-btn id="menu-button" color="white" text-color="black" label="未作成" @click="pageTransition('')"/>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="閉じる" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
  
<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent ({
  components: {},
  props: {
    'menuDialog': Boolean,
  },
  setup (props) {
    const dialog = ref(props.menuDialog as boolean)
    const showDialog = async() => {
      dialog.value = !dialog.value
    }
    return {
      props,
      dialog,
      showDialog,
    }
  },
  methods: {
    closeDaialog() {
      this.dialog = false;
    },
    pageTransition(pageName: string) {
      this.dialog = false;
      this.$router.push({ name: pageName });
    },
  },
  watch: {
    menuDialog: function() {
      this.dialog = (this.menuDialog == false) ? true : this.menuDialog;
    },
  }
});
</script>

<style lang="scss" scoped>
.logo-sized {
  width: 30px;
  // margin: 20px;
}
#menu-button {
  width: 100%;
  margin-bottom: 10px;
}
.menu {
  width: 100%;
}
.card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
